.swap {
	height: 30px;
	width: 30px;
	border-radius: 100%;
	background: var(--color-font);
	/* background: linear-gradient(to right,  white 0%, white 50%, black 50%, black 100%); */
	/* box-shadow: 0px 0px 2px 2px rgba(132, 132, 132, 0.25); */
	margin: 1px;
	z-index: 5;
	position: fixed;
	bottom: 5px;
	right: 5px;
}

@media only screen and (max-width: 800px) {
	.swap {
		top: 5px;
		right: 5px;
		height: 35px;
		width: 35px;
	}
}

@media print {
	.swap {
		display: none;
	}
}