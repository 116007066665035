:root {
	--color-primary: #ffffff;
	--color-accent: #F0F0F0;
	--color-font: #000000;
	--color-secondary-font: #00000077;
	--color-border: #9b9b9b;
	--disp: none;
	--height: 10rem;
}

html, body {
	margin: 0px;
	padding: 0px;
	height: 100%;
	overflow-y: visible;
	overflow-x: hidden;
	display: block;
}


body {
	margin: 0;
	font-family: "system-ui",-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,"sans-serif",Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
	/* font-family: 'Montserrat', sans-serif; */
	font-weight: 300;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: var(--color-primary);
	position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent;
	scrollbar-width: none; /* Firefox */
  	-ms-overflow-style: none;  /* IE 10+ */
	display: none;
	position: fixed;
}

/* @page {
	size: A4;
	margin: 1in !important;
} */