#reset-form {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
    z-index: 1;
    position: fixed;
    color: var(--color-font);
    font-size: 1rem;
}

#reset-form button {
    background: var(--color-accent);
    border: none;
    border-radius: 5px;
    color: var(--color-font);
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    height: 40px;
    margin: 10px 0;
    outline: none;
    padding: 0 20px;
    transition: all 0.2s ease-in-out;
    max-width: 400px;
    width: 100%;
}

#reset-form input {
    background: var(--color-accent);
    opacity: 0.8;
    border: none;
    border-radius: 5px;
    color: var(--color-font);
    font-size: 18px;
    font-weight: 400;
    height: 35px;
    margin: 10px 0;
    outline: none;
    padding: 0 25px;
    transition: all 0.2s ease-in-out;
    max-width: 400px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    width: 100%;
}

#error {
    color: red;
    font-size: 1rem;
    font-weight: 400;
    margin: 0px 20px;
    padding: 0px 20px;
    width: 100%;
    max-width: 400px;
}