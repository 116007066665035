.prompt {
    color: var(--color-font);
    font-size: 1rem;
    font-weight: 300;
    display: inline;
    width: 50%;
    top: 0px;
    max-width: 400px;
    margin: 10px 0px 0px 25px;
    position: relative;
    right: 0;
}

.promptText {
    opacity: 0.5;
    margin-right: 10px;
}

.promptButton {
    font-weight: 600;
    opacity: 1;
    color: var(--color-font);
    top: 0px;
    width: 50%;
    cursor: pointer;
}