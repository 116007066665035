.editor {
	background: var(--color-primary);
	color: var(--color-font);
	z-index: 1;
	position: fixed;
	/* font-family: 'Montserrat', sans-serif; */
	/* debugging print */
	/* border: 5px solid red; */
}

div.DraftEditor-root {
	background: var(--color-primary);
	color: var(--color-font);
	height: inherit;
	width: 100vw;
	position: absolute;
	word-wrap: break-word;
	/* debugging print */
	/* border: 5px solid blue; */
}

div.public-DraftEditor-content {
	overflow: auto;
	height: calc(100% - 0in);
	scrollbar-width: none; /* Firefox */
	-ms-overflow-style: none; /* IE 10+ */
}

@media only screen and (min-width: 1023px) {
	div.DraftEditor-editorContainer{
		height: calc(100% - 0in);
		padding: 1in;
		padding-bottom: 0;
		padding-top: 0;
	}
	div.public-DraftEditor-content {
		height: calc(100% - 2in); 
		padding-top: 1in;
		padding-bottom: 1in;
	}
	.editor {
		height: 100%;
		width: calc(100% - 2in);
	}
}

@media only screen and (max-width: 1024px) {
	div.DraftEditor-editorContainer{
		padding: 0.25in;
		padding-bottom: 0;
		padding-top: 0.5in;
		height: 100%;
	}
	div.public-DraftEditor-content {
		height: calc(100% - 0.5in);
		padding-top: 0.25in;
		padding-bottom: 0.25in;
	}
	.editor {
		height: calc(100% - 0.5in);
		width: 100%;
	}
}

@media only print {
	body {
		/* debugging print */
		border: 5px solid green;
		/* margin: 1in !important; */
	}

	@page {
		size: A4;
		margin: 0in !important;
		/* until we can figure out weird print margins */
		margin: 1in !important;
		/* width: 210mm;
		height: 297mm; */
		overflow: visible; 
	}
	div.DraftEditor-editorContainer{
		height: unset;
		width: 100%;
		padding: 0in !important;
		color: black !important;
		/* page-break-inside: always; */
	}
	div.public-DraftEditor-content {
		height: unset;
		width: 100%;
		padding: 0in !important;
	
	}
	div.public-DraftEditor-content > div > div {
		/* margin: 1in !important; */
		/* width: calc(100% - 2in) !important; */
		width: unset !important;
		/* border: 2px solid green !important; */
		background: white !important;
		/* page-break-before: always; */
	}
	.DraftEditor-root {
		/* margin: 1in !important; */
		/* width: calc(100% - 2in) !important; */
		width: unset !important;
		/* border: 2px solid green !important; */
		background: white !important;
	}
	.editor {
		/* min-height: 100%; */
		/* width: calc(100% - 2in) !important; */
		/* margin: 1in !important; */
		font-family: 'Times New Roman', Times, serif !important;
		background: white !important;
		/* border: 2px solid red !important; */
		position: relative !important;
	}
    html, body {
        /* height: 100%; */
		background: white !important;
	}
	/* @page:first {
 		 margin: 0; */
	/* } */
}

/* 
::selection {
  background: #ffb7b7; 
}
::-moz-selection {
  background: #ffb7b7; 
} */

#styling {
	height: auto;
	width: 50px;
	border-radius: 15px;
	background: var(--color-accent);
	z-index: 2;
	position: fixed;
	top: 5px;
	left: 5px;
	text-align: center;
	display: none;
}

@media print {
	#styling {
		display: none !important;
	}
}

@media only screen and (max-width: 1024px) {
	#styling {
		top: 5px;
		left: 5px;
		height: 50px;
		width: auto;
	}

	.material-icons {
		color: var(--color-font);
		cursor: pointer;
		line-height: 0;
		font-size: 20px;
	}

	#buttons {
		margin: 5px 10px 10px 10px;

	}
	
	button {
		padding: 10px;
		width: 30px;
		height: auto;
	}
}

@media only screen and (min-width: 1023px) {
	#buttons {
		margin: 10px 10px 10px 5px;
	}
}

.material-icons {
	color: var(--color-font);
	cursor: pointer;
	line-height: 0;
	/* font-family: 'Material Icons'; */
	font-weight: bold;
	font-style: normal;
	text-align: center;
	font-size: 24px;  /* Preferred icon size */
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
  
	/* Support for all WebKit browsers. */
	-webkit-font-smoothing: antialiased;
	/* Support for Safari and Chrome. */
	text-rendering: optimizeLegibility;
  
	/* Support for Firefox. */
	-moz-osx-font-smoothing: grayscale;
  
	/* Support for IE. */
	font-feature-settings: 'liga';
	top:0px;
}

button {
	background: none;
	border: none;
	text-align: center;
	padding: 0px;
	height: 30px;
	width: 30px;
	margin: 5px;

	position: relative;
}