@media only print {
	#console-bar,
	#addDoc {
		display: none;
	}
	.docs {
		margin-top: 50px !important;
	}
}

#console-cont {
	/* font-family: 'Inter', sans-serif; */
	font-weight: 400;
	z-index: 5;
	display: var(--disp);
	justify-content: center;
	position: relative;
}

#console-bar {
	background-color: var(--color-accent);
	border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
	padding: 1.25rem;
	padding-left: 1.5rem;
	font-size: 1.25rem;
	width: 50%;
	margin-top: 33vh;
	color: var(--color-font);
	position: absolute;
	border: 1px solid var(--color-font);
    /* box-shadow: 0px -0px 5px -3px var(--color-secondary-font); */
}

#console {
	box-sizing: border-box;
	background-color: var(--color-accent);
	font-size: 1.25rem;
	color: var(--color-font);
	text-rendering: geometricPrecision;
	width: 100%;
	outline: none;
	border: none;
	z-index: 2;
}

#autocomplete {
	position: absolute;
	font-size: 1.25rem;
	transform: translate(2.5px, 0.65px);
	color: transparent;
	font-weight: 400;
	outline: none;
	border: none;
	z-index: 1;
	user-select: none;
}

#autocomplete span {
	color: var(--color-secondary-font);
}

li {
	cursor: pointer;
}

h1 {
	color: var(--color-font);
	display: none;
}

@media only print {
	#console {
		display: none;
	}
}

@media only screen and (max-width: 600px) {
	#console {
		width: 100%;
		padding: 1rem;
	}
	.option {
		display: inline;
	}
  }

  #options {
	display: none;
	list-style-type: none;
	background-color: var(--color-accent);
	border-radius: 0rem 0rem 0.8rem 0.8rem;
	width: 50%;
	padding: 1.25rem;
	padding-left: 1.5rem;
	position: absolute;
	padding-top: 3vh;
	top: 33vh;
	z-index: 2;
  }
  
  #options li {
	margin-top: -1px; /* Prevent double borders */
	text-decoration: none;
	color: var(--color-font);
	padding: 0.5rem;
	font-size: 1.25rem;
	display: block;
	border-radius: 0.8rem;
  }
  
  #options li:hover:not(.header) {
	background-color: var(--color-accent);
	box-shadow: inset 0px 0px 15px -10px var(--color-primary);
	z-index: 6;
  }

.fade-text {
	animation: fade 0.15s;
}

@keyframes fade {
	from { color: var(--color-secondary-font); }
	to { color: var(--color-font); }
}


.error {
	animation: shake 0.15s;
	border: 1px solid #ff000045;
}
  
@keyframes shake {
	0% { transform: translate(1px, 1px) rotate(0.5deg); }
	50% { transform: translate(-2px, -1px) rotate(0deg); }
	100% { transform: translate(1px, -1px) rotate(-0.5deg); }
}