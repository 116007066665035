#root {
    height: 100%;
    overflow-y: visible;
    overflow-x: hidden;
    display: block;
}

#addDoc {
    background: var(--color-accent);
    border: none;
    border-radius: 5px;
    color: var(--color-font);
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    height: 40px;
    margin: 10px 0;
    outline: none;
    padding: 10px 20px;
    margin: 80px 10px 10px 10px;
    transition: all 0.2s ease-in-out;
    max-width: 150px;
    width: 100%;
    float: right;
    user-select: none;
    border: 1px solid var(--color-border);
}

.docs {
    margin-top: 150px;
    margin-bottom: 20px;
}

.docsContainer {
    height: 100%;
    margin: 0 auto;
}


@media screen and (max-width: 500px) {
    .docsContainer {
        /* width: 546px; */
        width: 364px;
    }
}
@media screen and (max-width: 768px) and (min-width: 500px)  {
    .docsContainer {
        width: 546px;
    }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
    .docsContainer {
        width: 728px;
    }
}
@media screen and (min-width: 1024px) {
    .docsContainer {
        width: 910px;
    }
}

.docsList {
    /* max-width: 100%; */
}

.doc {
    display: inline-flex;
    flex-direction: column;
    background: var(--color-accent);
    opacity: 0.8;
    border-radius: 5px;
    color: var(--color-font);
    font-size: 18px;
    font-weight: 400;
    height: 200px;
    margin: 20px 15px 0px 15px;
    outline: none;
    padding: 0 0;
    transition: all 0.2s ease-in-out;
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    border: 1px solid var(--color-border);
}

.doc .docBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.doc .docHeader {
    padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}

.doc h2 {
    flex: 1;
    font-size: 14px;
    font-weight: 600;
    margin: 1px 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.doc input {
    flex: 1;
    font-size: 14px;
    font-weight: 600;
    margin: 1px 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: transparent;
    border: none;
    outline: none;
    appearance: none;
    color: var(--color-font);
}

.doc p {
    font-size: 12px;
    font-weight: 400;
    margin: 5px;
    text-align: right;
}

.deleteDoc {
    color: var(--color-font);
    cursor: pointer;
    font-weight: 600;
    display: none;
}

@keyframes show {
    from {opacity: 0; scale: 0;}
    to {display: inline-block; opacity: 1; scale: 1;}
  }

.doc:hover .deleteDoc {
    display: inline-block;
    animation-name: show;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
}

#editTitle {
    display: flex;
    width: 100%;
}

#editTitle input {
    flex: 1;
    overflow: ellipsis;
}